<template>
  <div id="booker-page" :class="{'mobile': isMobile()}">
    <TitleHelper :title="'Booker Prize Kindle prices'"></TitleHelper>
    <div class="display">
        <div class="header">
            <div class="bold" style="padding-bottom: 3px;">Booker Prize longlist 
                <select v-model="bookerYear" @change="getDates">
                        <option v-for='t in this.bookerYears' :key="'year-select-'+t" :value="t">{{t}}</option>
                </select>
                <i class="chev-down"></i>
                Kindle prices</div>
            <div>Prices correct as of <span class="bold">{{dateTimeFormat(mostRecentDate)}}</span></div>
            <div style="position: relative;">Comparing with 
                    <select v-model="compareWithDate" @change="getComparePrices">
                        <option v-for='t in this.dates' :key="'date-select-'+t" :value="t">{{dateTimeFormat(t)}}</option>
                    </select>
                    <i class="chev-down"></i>
            </div>
            <div v-if="currentTotal && compareTotal">
                Now 
                <span class="bold">£{{currentTotal.toFixed(2)}}</span>, was 
                <span class="bold">£{{compareTotal.toFixed(2)}}</span> ( 
                <span class="bold" :class="{
                    'reduce': percentageChange(compareTotal, currentTotal) <= 0.05, 
                    'increase': percentageChange(compareTotal, currentTotal) >= 0.05}">
                    {{percentageChange(compareTotal, currentTotal) > 0 ? '+' : ''}}{{percentageChange(compareTotal, currentTotal).toFixed(2)}}%
                </span>)
            </div>
            <div v-else>
                <span class="bold">No data to compare yet</span>
            </div>
        </div>
        <div class="content">
            <div v-for="book in currentPrices" :key="'date-'+book._id" class="item"
                :class="{
                    'reduce': compare(book.title)['change'] < 0, 
                    'increase': compare(book.title)['change'] > 0,
                    'unreleased': !book.released,
                }">
                <a :href="'https://www.amazon.co.uk/gp/product/'+book.ASIN+'/ref=as_li_tl?tag=thebundlofjoy'">
                    <img :src="book.image" style="height: 50px;">
                    <div class="release-date" v-if="!book.released"> 
                        To be released: {{dateFormat(book.releaseDate)}}
                    </div>
                    <div class="details">
                        <div v-if="book.status" class="book-status">{{book.status}}</div>
                        <div class="title">{{book.title}}</div>
                        <div class="author">{{book.author}}</div>
                        <div class="price">£{{book.price}} (was £{{compare(book.title)['previousPrice']}}, change {{compare(book.title)['change'] > 0 ? '+' : ''}}{{compare(book.title)['change'].toFixed(2)}}%)</div>
                    </div>
                </a>
            </div>
            <div style="height: 115px;"></div>
        </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
const moment = require("moment");
const config = require("../../assets/config")
import TitleHelper from '../../helpers/vueTitleHelper.vue';

export default {
  name: "bookerPrize",
  beforeCreate: function() {
    document.body.className = 'booker';
  },
  data() {
    return {
        values: config,
        dates: [],
        currentPrices: [],
        comparePrices: [],
        mostRecentDate: null,
        compareWithDate: null,
        currentTotal: null,
        compareTotal: null,
        bookerYears: [],
        bookerYear: null
    };
  },
  mounted() {
      this.getBookerYears();
  },
  components: {
    TitleHelper,
  },
  methods: {
      getDates: function() {
        var config = {
            headers: {
            "Content-Type": "application/json"
            }
        };
        var body = {  };
        var baseUrl = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/booker/dates/" + this.bookerYear;
        axios
            .get(baseUrl,body,config)
            .then(response => {
                this.dates = response['data'];
                this.mostRecentDate = this.dates.pop();
                this.compareWithDate = this.dates[0];
                this.getCurrentPrices();
                this.getComparePrices();
            })
            .catch(error => {
                this.errorValue = error;
            })
      },
      getBookerYears: function() {
        var config = {
            headers: {
            "Content-Type": "application/json"
            }
        };
        var body = {  };
        var baseUrl = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/booker/years";
        axios
            .get(baseUrl,body,config)
            .then(response => {
                this.bookerYears = response['data'];
                this.bookerYear = this.bookerYears[0];
                this.getDates();
                this.getCurrentPrices();
                this.getComparePrices();
            })
            .catch(error => {
                this.errorValue = error;
            })
      },
      dateFormat: function(val) {
          return moment(val).format('DD/MM/YYYY');
      },
      dateTimeFormat: function(val) {
          return moment(val).format('DD/MM/YYYY HH:mm');
      },
      getCurrentPrices: function() {
        var config = {
            headers: {
            "Content-Type": "application/json"
            }
        };
        var body = {  };
        var baseUrl = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/booker/prices/"+ this.bookerYear + "?date=" + this.mostRecentDate;
        axios
            .get(baseUrl,body,config)
            .then(response => {
                this.currentPrices = response['data'];
                this.currentTotal = this.getTotal(this.currentPrices);
            })
            .catch(error => {
                this.errorValue = error;
            })
      },
      getComparePrices: function() {
        var config = {
            headers: {
            "Content-Type": "application/json"
            }
        };
        var body = {  };
        var baseUrl = this.values.BACKEND_CONNECTION + "://" + this.values.BACKEND_SERVER + ":" + this.values.BACKEND_SERVER_PORT + "/api/mongo/booker/prices/" + this.bookerYear + "?date=" + this.compareWithDate;
        axios
            .get(baseUrl,body,config)
            .then(response => {
                this.comparePrices = response['data'];
                this.compareTotal = this.getTotal(this.comparePrices);
            })
            .catch(error => {
                this.errorValue = error;
            })
      },
      getTotal: function(items) {
        return items.reduce(function(a,b) {
                    return a+b['price'];
                },0)
      },
      compare: function(title) {
          if (this.comparePrices.length > 0) {
            let currPrice = null;
            let oldPrice = null;
            for (let b of this.currentPrices) {
                if (b.title == title) {
                    currPrice = b.price;
                }
            }
            for (let b of this.comparePrices) {
                if (b.title == title) {
                    oldPrice = b.price;
                }
            }
            return {'previousPrice': oldPrice, 'change': this.percentageChange(oldPrice, currPrice)};
          } else {
            let currPrice = null;
            for (let b of this.currentPrices) {
                if (b.title == title) {
                    currPrice = b.price;
                }
            }
            return {'previousPrice': currPrice, 'change': 0};
          }

      },
      percentageChange: function(oldPrice, currPrice) {
          return (currPrice - oldPrice)/Math.abs(oldPrice) * 100;
      },
      isMobile: function() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
      },
  },
  computed: {
  },
  watch: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

div, select {
  font-family: "raleway", "Avenir", Helvetica, Arial, sans-serif;
}

select {
    font-size: 20px;
    font-weight: bold;
    background-color: #9c667b;
    border: none;
    -webkit-appearance:none;
    padding: 0 16px 0 4px;
}

div.header {
    height: 115px;
    font-size: 20px;
    overflow-x: hidden;
    white-space: nowrap;
    text-align: center;
}

.mobile div.header {
    height: 125px;
}

div.content {
    overflow: scroll;
    height: calc(100vh - 115px);
}

img {
    float: left;
    padding-top: 5px;
    padding-left: 3px;
}

.bold {
    font-weight: bold;
}

div.item {
    height: 60px;
    margin: 2px 0px;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

div.item.unreleased {
    background-color: #bc866b !important
}

div.content div.item:nth-child(odd) {
    background-color: #7c465b;
}

.reduce {
    color: #004400;
}

.increase {
    color: #440000;
}

div.details {
    padding-left: 40px;
}

div.details:hover div.book-status {
    opacity: 0.3;
}

div.release-date {
    position: absolute;
    right: 3px;
    bottom: 3px;
    font-size: 10px;
}

div.title {
    font-size: 22px;
    font-weight:bold;
    margin-top: -2px;
}

div.author {
    font-size: 14px;
    height: 14px;
    margin-top: -4px;
}

div.price {
    font-size: 12px;
    margin-top:3px;
}

a:link {
  text-decoration: none;
  color: inherit;
}

a:visited {
  text-decoration: none;
  color: inherit;
}

i.chev-down {
    border-width: 0 1.5px 1.5px 0;
    border-color: black;
    border-style: solid;
    width: 5px;
    height: 5px;
    position: absolute;
    top: 9px;
    margin-left: -12px;
    transform: rotate(45deg);
    pointer-events: none;
}

div.book-status {
    color: black;
    position: absolute;
    font-size: 60px;
    font-weight: 900;
    opacity: 0.05;
    width: 100%;
    text-align: right;
    right: 5px;
    top: -8px;
    text-transform: uppercase;
    transition: opacity 0.5s ease-in-out;
}

</style>

